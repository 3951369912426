import React, { useState } from 'react';
import ChatBot from 'react-chatbotify';
import OpenAI from 'openai';
import './sofabuddy_chatbot/styles.sass';
import makeRequest from '../lib/fetchService';
import './translations/componentsInit';
import closeChatIcon from '/app/javascript/icons/svgs/minus-white.svg';
import { useTranslation } from 'react-i18next';
import ErrorMessage from './sofabuddy_chatbot/ErrorMessage';

const SofabuddyChatBot = ({ sofabuddyCookieExists, sofabuddyThreadId }) => {
  const { t } = useTranslation();
  const apiKey = window.Sofatutor.Sofabuddy.openAi.apiKey;
  const openai = new OpenAI({
    apiKey: apiKey,
    dangerouslyAllowBrowser: true
  });

  let hasError = false;
  const [thread, setThread] = useState(null);
  const assistantId = 'asst_RGtBOYyGoRHTpPD7KWHNIpVo';
  const welcomeMessage = sofabuddyCookieExists
    ? t('sofabuddy_chat_bot.return_welcome_message')
    : t('sofabuddy_chat_bot.first_welcome_message');

  const setCookie = async threadId => {
    const apiUrl = '/sofabuddy_chat_bot_used';
    makeRequest(apiUrl, 'PATCH', { thread_id: threadId });
  };

  const retrieveOrCreateThread = async () => {
    if (window.Sofatutor.env == 'test') {
      return {
        id: 'test_thread_id'
      };
    }

    try {
      if (sofabuddyThreadId) {
        const existingThread = await openai.beta.threads.retrieve(
          sofabuddyThreadId
        );
        setThread(existingThread);
        return existingThread;
      } else {
        const newThread = await openai.beta.threads.create();
        setThread(newThread);
        setCookie(newThread.id);
        if (window.Sofatutor.profileLevel) {
          await setClassLevel(newThread.id);
        }
        return newThread;
      }
    } catch (error) {
      console.error('Error creating thread:', error);
      return null;
    }
  };

  const setClassLevel = async threadId => {
    try {
      await openai.beta.threads.messages.create(threadId, {
        role: 'assistant',
        content: `The Student you are teaching is in class level ${window.Sofatutor.profileLevel}. Use this information to adjust your teaching style.`
      });

      const run = await openai.beta.threads.runs.stream(threadId, {
        assistant_id: assistantId
      });

      await run.finalRun();
    } catch (error) {
      console.error('Error setting class level:', error);
    }
  };

  const sendMessage = async (params, thread) => {
    hasError = false;

    if (window.Sofatutor.env == 'test') {
      await params.streamMessage('This is a test message');
      return;
    }

    console.log('Sending message:', params.userInput);
    let text = '';

    try {
      await openai.beta.threads.messages.create(thread.id, {
        role: 'user',
        content: params.userInput
      });

      const run = await openai.beta.threads.runs.stream(thread.id, {
        assistant_id: assistantId
      });

      run.on('textDelta', async delta => {
        text += delta.value || '';
        await params.streamMessage(text);
      });

      await run.finalRun();
      trackConversation(params.userInput, text, thread.id);
    } catch (error) {
      await params.injectMessage(
        ErrorMessage(t('sofabuddy_chat_bot.error_message'))
      );
      hasError = true;
    }
  };

  const flow = {
    start: {
      message: sofabuddyThreadId ? '' : welcomeMessage,
      path: 'loop'
    },
    loop: {
      message: async params => {
        if (!thread) {
          const createdThread = await retrieveOrCreateThread();
          if (createdThread) {
            await sendMessage(params, createdThread);
          } else {
            hasError = true;
          }
        } else {
          await sendMessage(params, thread);
        }
      },
      path: () => (hasError ? 'start' : 'loop')
    }
  };

  const sharedChatHistoryStyle = {
    background: '#E6E8E5',
    borderRadius: '.25rem',
    color: '#3C3C3C',
    fontSize: '1rem'
  }

  const styles = {
    chatWindowStyle: {
      left: '1rem',
      height: '43.75rem',
      width: '43.75rem'
    },
    chatButtonStyle: {
      left: '1rem'
    },
    headerStyle: {
      height: '4rem',
      background: '#7866d3',
      fontWeight: 'bold',
      fontSize: '1.375rem',
      justifyContent: 'center',
      alignItems: 'center'
    },
    chatHistoryButtonStyle: {
      ...sharedChatHistoryStyle
    },
    chatHistoryButtonHoveredStyle: {
      ...sharedChatHistoryStyle,
      border: '1px solid #000'
    },
    botBubbleStyle: {
      fontFamily: 'Helvetica, Roboto, Arial, sans-serif',
      color: '#333',
      backgroundColor: '#E9E6F8'
    },
    userBubbleStyle: {
      color: '#333',
      backgroundColor: '#FDEB99'
    },
    chatInputContainerStyle: {
      padding: '1rem 1.5rem',
      borderTop: '1px solid #E1E1E1'
    },
    chatInputAreaStyle: {
      border: '1px solid #E1E1E1',
      borderRadius: '.5rem',
      boxSizing: 'border-box'
    },
    chatInputAreaFocusedStyle: {
      boxShadow: '#7866d3 0 0 0.3125rem'
    },
    sendButtonStyle: {
      backgroundColor: '#7866d3',
      height: '2.5rem'
    },
    sendButtonHoveredStyle: {
      backgroundColor: '#4b33c4',
      height: '2.5rem'
    },
    footerStyle: {
      background: '#F8F8F8',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Helvetica, Roboto, Arial, sans-serif',
      fontSize: '0.875rem',
      padding: '0.75rem',
      borderTop: '1px solid #E1E1E1'
    }
  };

  const trackConversation = async (question, answer, threadId) => {
    try {
      const trackUrl = window.Sofatutor.Sofabuddy.trackUsageDataUrl;
      makeRequest(trackUrl, 'POST', {
        question: question,
        answer: answer,
        thread_id: threadId
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <ChatBot
      styles={styles}
      settings={{
        header: {
          showAvatar: false,
          title: t('sofabuddy_chat_bot.title'),
          closeChatIcon: closeChatIcon
        },
        tooltip: {
          mode: 'NEVER'
        },
        notification: {
          disabled: true
        },
        general: {
          embedded: false,
          flowStartTrigger: 'ON_CHATBOT_INTERACT',
          fontFamily: 'Open Sans, Helvetica, Arial, sans-serif'
        },
        chatHistory: {
          storageKey: 'example_real_time_stream',
          viewChatHistoryButtonText: (
            <>
            {t('sofabuddy_chat_bot.show_chat_history')} <i className="icon icon--replay"></i>
            </>
          ),
          autoLoad: sofabuddyThreadId ? true : false
        },
        botBubble: {
          dangerouslySetInnerHtml: true,
          simStream: window.Sofatutor.env == 'test' ? false : true
        },
        chatInput: {
          enabledPlaceholderText: t('sofabuddy_chat_bot.placeholder_text')
        },
        footer: {
          text: t('sofabuddy_chat_bot.mistake_hint')
        },
        fileAttachment: {
          disabled: true
        },
        emoji: {
          disabled: true
        }
      }}
      flow={flow}
    />
  );
};

export default SofabuddyChatBot;
